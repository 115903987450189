import { default as _slugO4OgsLYKIAMeta } from "/src/pages/_slug.vue?macro=true";
import { default as _404FBzoLTrQphMeta } from "/src/pages/404.vue?macro=true";
import { default as _91id_93Sfr5eWvg4iMeta } from "/src/pages/blog/[id].vue?macro=true";
import { default as index380d8Ul5jBMeta } from "/src/pages/blog/index.vue?macro=true";
import { default as BookingR5LBMA9yANMeta } from "/src/pages/Booking.vue?macro=true";
import { default as contactsVzkVHECPHfMeta } from "/src/pages/contacts.vue?macro=true";
import { default as corporatejY9bs6XThjMeta } from "/src/pages/corporate.vue?macro=true";
import { default as franchise7xUIuzrzUAMeta } from "/src/pages/franchise.vue?macro=true";
import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as partnerka8Hnfx081bgMeta } from "/src/pages/partnerka.vue?macro=true";
import { default as _91id_93TBcw7wySQEMeta } from "/src/pages/reservation/[id].vue?macro=true";
import { default as termswfI93HZwXyMeta } from "/src/pages/terms.vue?macro=true";
import { default as user_45agreement9laFm2ekIhMeta } from "/src/pages/user-agreement.vue?macro=true";
import { default as _slugwffj03MhPjMeta } from "~/pages/_slug.vue?macro=true";
import { default as _91id_93xlpFJ7eH29Meta } from "~/pages/blog/[id].vue?macro=true";
import { default as terms6ldi9JYIvUMeta } from "~/pages/terms.vue?macro=true";
import { default as user_45agreementC5NiffWMRKMeta } from "~/pages/user-agreement.vue?macro=true";
import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
export default [
  {
    name: _slugO4OgsLYKIAMeta?.name ?? "_slug",
    path: _slugO4OgsLYKIAMeta?.path ?? "/_slug",
    meta: _slugO4OgsLYKIAMeta || {},
    alias: _slugO4OgsLYKIAMeta?.alias || [],
    redirect: _slugO4OgsLYKIAMeta?.redirect,
    component: () => import("/src/pages/_slug.vue").then(m => m.default || m)
  },
  {
    name: _404FBzoLTrQphMeta?.name ?? "404",
    path: _404FBzoLTrQphMeta?.path ?? "/404",
    meta: _404FBzoLTrQphMeta || {},
    alias: _404FBzoLTrQphMeta?.alias || [],
    redirect: _404FBzoLTrQphMeta?.redirect,
    component: () => import("/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Sfr5eWvg4iMeta?.name ?? "blog-id",
    path: _91id_93Sfr5eWvg4iMeta?.path ?? "/blog/:id()",
    meta: _91id_93Sfr5eWvg4iMeta || {},
    alias: _91id_93Sfr5eWvg4iMeta?.alias || [],
    redirect: _91id_93Sfr5eWvg4iMeta?.redirect,
    component: () => import("/src/pages/blog/[id].vue").then(m => m.default || m)
  },
  {
    name: index380d8Ul5jBMeta?.name ?? "blog",
    path: index380d8Ul5jBMeta?.path ?? "/blog",
    meta: index380d8Ul5jBMeta || {},
    alias: index380d8Ul5jBMeta?.alias || [],
    redirect: index380d8Ul5jBMeta?.redirect,
    component: () => import("/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: BookingR5LBMA9yANMeta?.name ?? "Booking",
    path: BookingR5LBMA9yANMeta?.path ?? "/Booking",
    meta: BookingR5LBMA9yANMeta || {},
    alias: BookingR5LBMA9yANMeta?.alias || [],
    redirect: BookingR5LBMA9yANMeta?.redirect,
    component: () => import("/src/pages/Booking.vue").then(m => m.default || m)
  },
  {
    name: contactsVzkVHECPHfMeta?.name ?? "contacts",
    path: contactsVzkVHECPHfMeta?.path ?? "/contacts",
    meta: contactsVzkVHECPHfMeta || {},
    alias: contactsVzkVHECPHfMeta?.alias || [],
    redirect: contactsVzkVHECPHfMeta?.redirect,
    component: () => import("/src/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: corporatejY9bs6XThjMeta?.name ?? "corporate",
    path: corporatejY9bs6XThjMeta?.path ?? "/corporate",
    meta: corporatejY9bs6XThjMeta || {},
    alias: corporatejY9bs6XThjMeta?.alias || [],
    redirect: corporatejY9bs6XThjMeta?.redirect,
    component: () => import("/src/pages/corporate.vue").then(m => m.default || m)
  },
  {
    name: franchise7xUIuzrzUAMeta?.name ?? "franchise",
    path: franchise7xUIuzrzUAMeta?.path ?? "/franchise",
    meta: franchise7xUIuzrzUAMeta || {},
    alias: franchise7xUIuzrzUAMeta?.alias || [],
    redirect: franchise7xUIuzrzUAMeta?.redirect,
    component: () => import("/src/pages/franchise.vue").then(m => m.default || m)
  },
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index",
    path: indexvsa0KwJWqiMeta?.path ?? "/",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: partnerka8Hnfx081bgMeta?.name ?? "partnerka",
    path: partnerka8Hnfx081bgMeta?.path ?? "/partnerka",
    meta: partnerka8Hnfx081bgMeta || {},
    alias: partnerka8Hnfx081bgMeta?.alias || [],
    redirect: partnerka8Hnfx081bgMeta?.redirect,
    component: () => import("/src/pages/partnerka.vue").then(m => m.default || m)
  },
  {
    name: _91id_93TBcw7wySQEMeta?.name ?? "reservation-id",
    path: _91id_93TBcw7wySQEMeta?.path ?? "/reservation/:id()",
    meta: _91id_93TBcw7wySQEMeta || {},
    alias: _91id_93TBcw7wySQEMeta?.alias || [],
    redirect: _91id_93TBcw7wySQEMeta?.redirect,
    component: () => import("/src/pages/reservation/[id].vue").then(m => m.default || m)
  },
  {
    name: termswfI93HZwXyMeta?.name ?? "terms",
    path: termswfI93HZwXyMeta?.path ?? "/terms",
    meta: termswfI93HZwXyMeta || {},
    alias: termswfI93HZwXyMeta?.alias || [],
    redirect: termswfI93HZwXyMeta?.redirect,
    component: () => import("/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: user_45agreement9laFm2ekIhMeta?.name ?? "user-agreement",
    path: user_45agreement9laFm2ekIhMeta?.path ?? "/user-agreement",
    meta: user_45agreement9laFm2ekIhMeta || {},
    alias: user_45agreement9laFm2ekIhMeta?.alias || [],
    redirect: user_45agreement9laFm2ekIhMeta?.redirect,
    component: () => import("/src/pages/user-agreement.vue").then(m => m.default || m)
  },
  {
    name: _slugwffj03MhPjMeta?.name ?? "city",
    path: _slugwffj03MhPjMeta?.path ?? "/:slug",
    meta: _slugwffj03MhPjMeta || {},
    alias: _slugwffj03MhPjMeta?.alias || [],
    redirect: _slugwffj03MhPjMeta?.redirect,
    component: () => import("~/pages/_slug.vue").then(m => m.default || m)
  },
  {
    name: _slugwffj03MhPjMeta?.name ?? "region",
    path: _slugwffj03MhPjMeta?.path ?? "/regions/:slug",
    meta: _slugwffj03MhPjMeta || {},
    alias: _slugwffj03MhPjMeta?.alias || [],
    redirect: _slugwffj03MhPjMeta?.redirect,
    component: () => import("~/pages/_slug.vue").then(m => m.default || m)
  },
  {
    name: _91id_93xlpFJ7eH29Meta?.name ?? "news_page",
    path: _91id_93xlpFJ7eH29Meta?.path ?? "/regions/:city/:slug",
    meta: _91id_93xlpFJ7eH29Meta || {},
    alias: _91id_93xlpFJ7eH29Meta?.alias || [],
    redirect: _91id_93xlpFJ7eH29Meta?.redirect,
    component: () => import("~/pages/blog/[id].vue").then(m => m.default || m)
  },
  {
    name: terms6ldi9JYIvUMeta?.name ?? "terms",
    path: terms6ldi9JYIvUMeta?.path ?? "/terms",
    meta: terms6ldi9JYIvUMeta || {},
    alias: terms6ldi9JYIvUMeta?.alias || [],
    redirect: terms6ldi9JYIvUMeta?.redirect,
    component: () => import("~/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: user_45agreementC5NiffWMRKMeta?.name ?? "user-agreement",
    path: user_45agreementC5NiffWMRKMeta?.path ?? "/user-agreement",
    meta: user_45agreementC5NiffWMRKMeta || {},
    alias: user_45agreementC5NiffWMRKMeta?.alias || [],
    redirect: user_45agreementC5NiffWMRKMeta?.redirect,
    component: () => import("~/pages/user-agreement.vue").then(m => m.default || m)
  },
  {
    name: indexp9V2p5fxycMeta?.name ?? "index",
    path: indexp9V2p5fxycMeta?.path ?? "/",
    meta: indexp9V2p5fxycMeta || {},
    alias: indexp9V2p5fxycMeta?.alias || [],
    redirect: indexp9V2p5fxycMeta?.redirect,
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  }
]